<template>
  <div class="faq-content">
    <faq-card v-for="item in cards" :key="item.Id" :data="item"></faq-card>
    <faq-card
      v-if="cards.length === 0"
      key="empity"
      :data="{
        Name: '去其他页面看看吧~',
        ConsultingDescription: '暂未发布文章',
        Time: null,
      }"
    >
    </faq-card>
    <div class="faq-card more" v-if="list.length > 0">
      <span @click="getMore">更多>></span>
    </div>
  </div>
</template>

<script>
import FaqCard from "./FaqCard.vue";
import { get } from "@/plugins/axios";
export default {
  components: { FaqCard },
  data() {
    return {
      list: [],
      items: [],
      index: undefined,
      pageSize: 10,
    };
  },
  methods: {
    async getList() {
      await get("/HomeApi/GetFaqListCount")
        .then((data) => {
          this.list = data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getItems(filename) {
      await get("/HomeApi/GetFaqByCountId", { countId: filename })
        .then((data) => {
          this.items.push(...data);
        })
        .catch((e) => {
          console.log(e);
        });
      // const { items } = await get(`/data/list/${filename}.json`);
    },
    getMore() {
      this.index = this.list.shift();
    },
    async init() {
      await this.getList();
      this.getMore();
    },
  },
  computed: {
    tag() {
      return this.$route.name;
    },
    cards() {
      if (this.tag == "faq-news")
        return this.items.filter((f) => f.Category === "GSXW");
      else if (this.tag == "faq-trends")
        return this.items.filter((f) => f.Category === "HYDT");
      else if (this.tag == "faq-report")
        return this.items.filter((f) => f.Category === "MTBD");
      return [...this.items];
    },
  },
  watch: {
    index() {
      if (this.index) this.getItems(this.index);
    },
    cards() {
      if (this.cards.length < this.pageSize && this.index) {
        this.getMore();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.faq-content {
  background: rgba(245, 249, 252, 1);
  min-height: 500px;
  padding: 50px 0;
  .faq-card {
    width: 60%;
    max-width: 1440px;
    min-width: 1000px;
    margin: 0 auto;
  }
  .faq-card + .faq-card {
    margin-top: 30px;
  }

  .more {
    text-align: right;
    font-weight: 500;
    font-size: 1rem;
  }

  .more:hover {
    color: orange;
  }

  .more span {
    cursor: pointer;
  }
}
</style>
