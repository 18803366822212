<template>
  <div class="faq-card">
    <div class="img-wrap">
      <!-- <img
        :src="'http://157.0.1.214:8016' + data.ImagePath"
        v-if="data.ImagePath"
      /> -->
      <img :src="data.ImagePath" v-if="data.ImagePath" />
    </div>
    <article>
      <h5 v-text="data.Name" @click="handleClick"></h5>
      <p
        v-text="data.ConsultingDescription"
        style="-webkit-box-orient: vertical"
      ></p>
      <span class="date">
        {{
          this.dateFormat(
            data.Time === "" || data.Time === null
              ? new Date()
              : new Date(
                  parseInt(
                    String(data.Time).replace("/Date(", "").replace(")/", "")
                  )
                )
          )
        }}
      </span>
    </article>
  </div>
</template>

<script>
import { dateFormat } from "../../plugins/common.js";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$router.push(`/faq/detail/${this.data.Id}`);
    },
    dateFormat,
  },
};
</script>

<style lang="less" scoped>
.faq-card {
  background: #fff;
  padding: 10px 30px 10px 15px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 40px;
  height: 120px;

  .img-wrap {
    text-align: center;
    background: #f2f2f2;
    width: 213px;
    height: 120px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  article {
    overflow: hidden;
    color: #999;
    font-size: smaller;
    position: relative;
    //cursor: pointer;
    h5 {
      margin: 5px 0;
      font-size: larger;
      font-weight: 500;
      color: #333;
      cursor: pointer;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      //line-height: 20px;
      line-height: 2;
    }
    .date {
      position: absolute;
      bottom: 0px;
      //left: "80%";
      right: 0px;
    }
  }
}
</style>
